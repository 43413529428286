import diagram from './imgs/diagram.png'
import map from './imgs/map.jpg'
import modulator_png from './imgs/modulator.png';
import translation from './imgs/Global_link_diagram.png';
import shop from './imgs/shop.png';
import shopE from './imgs/shopE.png';
import shopB from './imgs/shopB.png';
import Header from './Instruments/Header'
import Footer from './Instruments/Footer'
import Canvas from './Instruments/Canvas'
import React, {useEffect} from 'react'

import translate from './../../Translate/mainPage.json';
import tariffsTr from './../../Translate/tariffs.json';
import { useNavigate } from 'react-router-dom';

function Component1({signStatus, decoders}){  
  const [language, setLanguage] = React.useState('eng');
  useEffect(() => {
    document.body.style.overflow = 'auto';
    setLanguage(JSON.parse( localStorage.getItem('choosen-language')))
  }, [])

  const getLanguage = (item) => {
    setLanguage(item);
  }

  const nav = useNavigate();
  const toProduct = () => {
    if (decoders === 'error') nav('/main/login');
    else if(decoders === null) window.location.href = 'https://www.globlink.ge/?product=67'
    else nav('/main/balance')
  }

  return (
  <div>
    <Header signStatus={signStatus} getLanguage={getLanguage}/>
    <div className='main'>
      <div className='in'>
        <h2 className='main-pages-titles'>{translate.name[language]}</h2>
        <p>{translate.prase1[language]}
        </p>
        <ul>
          <li>{translate.prase2[language]} <span className='pointed'>{translate.prase3[language]}</span></li>
          <li>{translate.prase4[language]} <span className='pointed'>{translate.prase5[language]}</span></li>
          <li>{translate.prase6[language]} <span className='pointed'>{translate.prase7[language]}</span></li>
        </ul>
        <iframe
              className='videos' 
              width="200"
              height="120"
              title="Global Link account installation" 
              src="https://www.youtube.com/embed/ebuuXLSR3co?si=fCVkSK0Z09BPA9CI"
              frameborder="0"
              allowfullscreen=""/>
        <img src={map} alt="map" className='map'/>
        <p>{translate.prase8[language]}</p>
        <p>{translate.prase9[language]}</p>
        <div className='grid-container'>
          <div className="grid-item"><p><span className='pointed'>{translate.prase10[language]}</span></p>
            <p>→ {translate.prase11[language]}</p>
            <p>→ {translate.prase12[language]}</p>
            <p>→ {translate.prase13[language]}</p>
            <p>→ {translate.prase14[language]}</p>
          </div>
          <div className="grid-item"><p><span className='pointed'>{translate.prase15[language]}</span></p>
            <p>→ {translate.prase16[language]}</p>
            <p>→ {translate.prase17[language]}</p>
            <p>→ {translate.prase18[language]}</p>
            <p>→ {translate.prase19[language]}</p>
          </div>
          <div className="grid-item"><p><span className='pointed'>{translate.prase20[language]}</span></p>
            <p>{translate.prase21[language]}</p>
            <p><span className='pointed'>{translate.prase22[language]}</span></p>
            <p>{translate.prase23[language]}</p>
            <p><span className='pointed'>{translate.prase24[language]}</span></p>
            <p>{translate.prase25[language]}</p>
          </div>
        </div>
        <h2 className='main-pages-titles'>{tariffsTr.prase2[language]}</h2>
        <ol>
          <li>
            <div>{tariffsTr.prase3[language]}</div>
            <div className='devices'>
              <a href="https://www.globlink.ge/?product=67"><img src={shopE} alt="" className="shopE"/> </a>
              <iframe 
              className='videos'
              width="200" 
              height="120" 
              src={translate.ytLinks[language]} 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen=""/>
            </div>
          </li>
          <li>
            <div>{tariffsTr.prase4[language]}</div>
            <div className='devices'>
              <a href="https://www.globlink.ge/?product=dvb-s2-to-ip-asi-streamer-descrambler"><img src={shopB} alt="" className="shopB"/></a>
              <iframe 
              className='videos'
              width="200" 
              height="120" 
              src="https://www.youtube.com/embed/7GqanVGso5Q?si=FHXL7swIrXQ7utR5" 
              title="YouTube video player" 
              frameborder="0" 
              allowfullscreen=""/>
            </div>
          </li>
        </ol>
        <p></p>
        <h2 className='main-pages-titles'>{tariffsTr.prase1[language]}</h2>
        <div className='packages-tariff'>
          <div className='packages-tariff-grid-container'>
            <div className='packages-tariff-card'>
            <div>
              <span className='pointed'>Setanta Sports Package (USD6 per 30 days):</span>
              <ul>
                <li>
                  Setanta Sports 1 (Eng, Geo, Ukr, Rus)
                </li>
                <li>
                  Setanta Sports 2 (Eng, Geo, Ukr, Rus)
                </li>
                <li>
                  Setanta Sports 3 (Eng, Geo, Ukr, Rus)
                </li>
              </ul>
            </div>
            <button onClick={toProduct} className='tariff-btn'>Buy service</button>
            </div>
            <div className='packages-tariff-card'>
              <div>
                <span className='pointed'>Sport Plus Package (USD3 per 30 days):</span>
                <ul>
                  <li>
                    Eurosport 1 (Eng)
                  </li>
                  <li>
                    Eurosport 2 (Eng)
                  </li>
                  <li>
                    Rugby TV (Geo)
                  </li>
                  <li>
                    Chess TV (Geo)
                  </li>
                  <li>
                    1TV Sport (Geo)
                  </li>
                </ul>
              </div>
              <button onClick={toProduct} className='tariff-btn'>Buy service</button>
            </div>

          </div>
        </div>
        <h2 className='main-pages-titles'>{tariffsTr.prase5[language]}</h2>
        <div className='our-service-for-subs'>
              <div>{tariffsTr.prase6[language]}</div>
              <div>{tariffsTr.prase7[language]}</div>
              <div>{tariffsTr.prase8[language]}</div>
              <div>{tariffsTr.prase9[language]}</div>
              <div>{tariffsTr.prase10[language]}</div>
              <iframe
              className='videos' 
              width="200"
              height="120"
              title="Global Link account installation" 
              src={translate.ytLinks1[language]}
              frameborder="0"
              allowfullscreen=""/>
            </div>
        {/* <a className='shopLink' href="https://www.globlink.ge/?product=67"><img src={shop} alt="" className="shop"/></a> */}
        {/* "https://www.youtube.com/embed/1doweWGds9s?feature=oembed" */}
        <p>{translate.prase26[language]}.</p>
        <div><img src={translation} alt="translation" className='map'/></div>
        <div><img src={diagram} alt="diagram" className='map'/></div>
        <div className='canvas'>
          <Canvas />
          <div className='overlay'>
            <p><a className='modul-animated' href="https://dvb4all.com/?product=dvb-ss2-modulatormultiplexer">{translate.prase27[language]}</a></p>
            <p><img src={modulator_png} alt="" /></p>
          </div>   
        </div>
        
      </div>
    </div>
    <Footer language={language}/>
  </div>
  );
};

export default Component1;